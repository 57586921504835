<template>

<div>
     <!-- Breadcrumb start -->
      <ol class="breadcrumb">
          <li class="breadcrumb-item">Clients</li>
      </ol>
      <!-- Breadcrumb end -->

      <!-- App actions start -->
      <b-button
        to="clients/new"
        class="btn btn-success float-left">
        <b-icon icon="plus-circle" />
        Add Client
      </b-button>

  <BMSTable
    :items="items"
    :fields="fields"
    :sort-by="sortBy"
    :urlBuilder="buildUrl"
  >
  </BMSTable>

</div>
</template>

<script>

import { getAllClients } from '@/api/clients.api'
import { getAllProjects } from '@/api/projects.api'
import BMSTable from '@/components/BMSTable';
import crudMixin from '@/api/crud'
import Vue from 'vue'

export default {
  mixins: [crudMixin],
  data() {
    return {
      fields: [{
        key: 'company',
        label: 'Company',
        sortable: true,
      }, {
        key: 'contact',
        label: 'Contact',
        sortable: true,
      }, {
        key: 'email',
        label: 'Email',
        sortable: true,
      }, {
        key: 'address.country',
        label: 'Country',
        sortable: true,
      }, {
        key: 'accounts_payable.currency',
        label: 'Currency',
        sortable: true,
      },{
        key: 'total_projects',
        label: 'Projects',
        sortable: true,
      }],
      items: [],
      filter: null,
      sortBy: 'company',
    }
  },
  mounted() {
    this.getClients();
  },
  methods: {
    getClients() {
        this.call(
            getAllClients(),
            (res) => {
                this.items.splice(0, this.items.length)
                res.data.clients.forEach(client => {
                    this.items.push(client)
                })
                this.getProjects()
            }
        )
    },
    getProjects(){
        this.call(
            getAllProjects(),
            (res)=>{
                let project_counts = {}
                res.data.projects.forEach(project => {
                    project_counts[project.client.id] = (project_counts[project.client.id] || 0) + 1
                })
                this.items.forEach(item => {
                    Vue.set( item, 'total_projects', project_counts[item.id] || 0 )
                })
            }
        )
    },
    buildUrl(client){
      return { name: 'Client', params: { id: client.id }}
    }
  },
  components: {
      BMSTable
  }
}

</script>
